import React, { useEffect, useState } from "react";
import { useCoreAPIURL, useViewPortSize } from "../../../hooks";
import { IKeyReplacementDetails } from "../../../pages/customer-service/keys-replacement";
import LostAssistanceForm, { ILostAssistanceForm } from "../LostAssistanceForm";
import StepsForForm from "./components/StepsForForm";
import scrollTo from "gatsby-plugin-smoothscroll";
import SuccesfullyRegistered from "./components/SuccesfullyRegistered";
import { stackStyled, useStackStyletron } from "../../../theme";
import { ErrorForm } from "../../../atoms/Cards";
import TitleAndSubtitle from "../../../atoms/Containers/TitleAndSubtitle";
import { Button } from "../../../atoms";
import { redirectFunc } from "../../../hooks/useRedirect";

const KeyReplacementContainer = stackStyled(
  "div",
  ({ formSubmited }: { formSubmited: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: formSubmited ? 0 : "0",
  })
);

const ReducedContainer = stackStyled("div", ({ isMobile }: { isMobile }) => ({
  maxWidth: isMobile ? "90%" : "60%",
  marginTop: isMobile ? "10px 0 0 0" : "20px 0 0 0",
}));

const KeyReplacement = (props: IKeyReplacementDetails) => {
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();

  // STATE
  const coreAPI_URL = useCoreAPIURL();
  const [email, setEmail] = useState("");
  const [formSubmited, setFormSubmited] = useState(false);
  const [showFetchError, setShowFetchError] = useState(false);

  useEffect(() => {
    if (formSubmited === true) {
      window.location.hash = "submited";
    }
  }, [formSubmited]);

  // HANLDERS && FUNCTIONS

  async function handleFormSubmited(value: ILostAssistanceForm) {
    setFormSubmited(true);
    scrollTo("#main");
    setEmail(value.email);
    value.comesFrom = "KeyReplacement";
    const response = await fetch(coreAPI_URL + "/lost-combination", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        return r;
      })
      .catch((e) => setShowFetchError(true));
  }

  return (
    <KeyReplacementContainer formSubmited={formSubmited} id="main">
      {showFetchError && <ErrorForm />}
      {!formSubmited ? (
        <>
          <TitleAndSubtitle
            isKeys={true}
            title={props.title}
            subTitle={props.subTitle}
            colortitle={theme.stackOnColors.primaryBlack}
            colorSubTitle={theme.stackOnColors.primaryBlack}
            $marginTop={isMobile ? "" : "60px"}
          />
          <ReducedContainer isMobile={isMobile}>
            <StepsForForm steps={props.steps} note={props.note} />
            {/* <LostAssistanceForm
              onFormSentSuccesfully={(value) => {
                handleFormSubmited(value);
              }}
            /> */}
            <Button.RoundedButton
              ariaLabel={"Open a Ticket"}
              aria-label={"Open a Ticket"}
              onClick={() =>
                (window.location.href =
                  "https://customersupport.stack-on.com/portal/newticket")
              }
              size="large"
              color="primary"
              $style={{
                width: "fit-content",
                margin: "70px 0 50px",
              }}
            >
              Open a Ticket
            </Button.RoundedButton>
          </ReducedContainer>
        </>
      ) : (
        <SuccesfullyRegistered email={email} />
      )}
    </KeyReplacementContainer>
  );
};

export default KeyReplacement;
